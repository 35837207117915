<link *ngIf="darkmodeEnabled" href="/assets/styles/dark.css" rel="stylesheet" type="text/css">
<div *ngIf="!loading" class="main" [ngClass]="{sideNavClosed: !displayNav}">
    <div class="side">
        <ng-container *ngIf="isAuthorized; else login">
            <lib-side-toolbar *ngIf="displayTool" ></lib-side-toolbar>
            <lib-side-navbar *ngIf="displayNav" [version]="version"></lib-side-navbar>
        </ng-container>
        <ng-template #login>
            <lib-side-banner></lib-side-banner>
        </ng-template>
    </div>
    <div class="page">
        <router-outlet></router-outlet>
    </div>
</div>
<lib-growler></lib-growler>
<lib-loading-indicator *ngIf="loading" [isLoading]="loading"></lib-loading-indicator>