<div class="sideNavbar">
    <div class="title" data-i18n="AppName">Ziti Console</div>
    <div id="Version" class="version">{{version}}</div>
    <div id="CustomLogo"></div>
    <div class="side-navigator-menu-container">
        <div *ngFor="let group of currentNav.groups" class="side-navigator-menu-group">
            <span class="side-navigator-menu-group-label">{{ group.label }}</span>
            <div *ngFor="let item of group.menuItems">
                <div class="side-navigator-menu-item {{item.className}}" [routerLink]="item.route" *ngIf="!item.hidden"
                     [ngClass]="{ selected: item.selectedRoutes?.includes(url) }">
                    <div class="side-navigator-menu-item-icon">
                        <div class="image" [ngClass]="item.iconClass"></div>
                        <span *ngIf="item.badgeCount" class="counterBadge">{{ item.badgeCount }}</span>
                    </div>
                    <span class="side-navigator-menu-item-label">{{ item.label }}
                        <span class="side-navigator-menu-item-sublabel">{{item.sublabel}}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
